import React, { Component, Suspense } from 'react';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import { Switch, Route } from 'react-router';
import { AccessDenied } from 'pages';
import LoginPage from './pages/LoginPage';
import { ThemeProvider } from 'styled-components';
import BaseTheme from './styled/theme';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

const AsyncAdminContainer = React.lazy(() => import('pages/Admin'));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={BaseTheme}>
            <Suspense fallback="<h1>Loading...</h1>">
              <Switch>
                <Route path="/accessdenied" component={AccessDenied} />
                <Route path="/login" component={LoginPage} />
                <PrivateRoute component={AsyncAdminContainer} />
              </Switch>
            </Suspense>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
